import React, { useState, useCallback, useEffect } from "react";
import "./Ticket.css";
import { Spin, Form, Input } from "antd";
import { FormattedMessage, IntlProvider } from "react-intl";
import NavigationSteps, { SingleStep } from "./steps";
import FirstStep from "./steps/first-step";
import SecondStep from "./steps/second-step";
import ThirdStep from "./steps/third-step";
import FinalStep from "./steps/final-step";
import useLocale from "../hooks/use-locale";
import useTicketOneEvents from "../hooks/use-ticketone-events";
import useFindEvent from "../hooks/use-filter-event";
import { Moment } from "moment";
import { ITOSeatGroupByBlocks } from "../interfaces/ticketone-interfaces";
import FourthStep from "./steps/fourth-step";
import { IQuantity } from "../interfaces/local-interfaces";
import LimitReached from "./steps/limit-reached-step";
import getLimit from "../hooks/use-ticketone-purchase-limit";
const DATE_FORMAT: string = "DD.MM.yyyy";

const App: React.FC = () => {
  const [loading, eventList] = useTicketOneEvents(
    window.adt_ticket_data.to_production_id
  );
    
  const [current, setCurrent] = useState<number>(0);
  const [date, setDate] = useState<Moment>();
  const [seatGroup, setSeatGroup] = useState<ITOSeatGroupByBlocks>();
  const [totalQty, setTotalQty] = useState<number>(0);
  const [quantities, setQuantities] = useState<IQuantity[]>([]);
  const [locale, translations] = useLocale();
  
  const event = useFindEvent(eventList, date?.format(DATE_FORMAT));
  const isTicketoneProvider:boolean = window.adt_ticket_data.provider === "TICKETONE";
  
  const [active, setActive] = useState<boolean>();
  const [available, setAvailable] = useState<number | null>(null);
  const isLimit:boolean = (available === null || available > 0);

  const updateLimit = useCallback(async () => {
    if(isTicketoneProvider){
      const newLimit = await getLimit();
      setAvailable(newLimit);
    }
  }, [isTicketoneProvider]);
  
  const reloadComponent = useCallback(()=>{
    setActive(false);
    updateLimit();
    setTimeout(() => {      
      setActive(true);
    }, 500);
  },[updateLimit]);

  const resetValues = useCallback(async () => {
    setCurrent(0);
    setDate(undefined);
    setSeatGroup(undefined);
    setQuantities([]);
    setTotalQty(0);
  }, []);

  const func = useCallback(() => {
    reloadComponent();
    resetValues();
  },[reloadComponent, resetValues]);

  const prev = useCallback(() => {
    setCurrent(current - 1);
    }, [current]);

  const next = useCallback(() => {
    setCurrent(current + 1);
  }, [current]);

  const onAddToCart = useCallback(() => {
    setCurrent(isTicketoneProvider ? 4 : 3);
  }, [isTicketoneProvider]);
  
  const onClickNew = useCallback(() => {
    setActive(false);
    func();
  }, [func]);

  const onSelectedSeatsGroup = useCallback(
    (val: ITOSeatGroupByBlocks | undefined) => {
      setSeatGroup(val);
    },
    []
  );

  const steps = (): SingleStep[] => {
    const steps: SingleStep[] = [
      {
        title: translations["steps.day"],
        description: date?.format(DATE_FORMAT) || "-",
      },
    ];   

    // only if ticketone is available
    isTicketoneProvider &&
        steps.push({
            title: translations["steps.hour"],
            description: seatGroup?.blockText || "-",
        });

    steps.push({
      title: translations["steps.quantity"],
      description: totalQty ? `${totalQty}` : "-",
    });
    steps.push({
      title: translations["steps.names"],
      description: "",
    });
    steps.push({
      title: translations["steps.done"],
      description: "",
    });
    return steps;
  }; 
  
  useEffect(() => {    
    window.jQuery(document.body).on("removed_from_cart",func);
    func();
  },[func]);
 
  return (
      <>
          {active ? (
              <IntlProvider locale={locale} messages={translations}>
                  <Spin spinning={loading} delay={500}>
                      <p>
                          <strong>
                              <FormattedMessage id="title" />
                          </strong>
                      </p>
                      <div className={"steps-panel"}>
                          <NavigationSteps current={current} steps={steps()} />
                          <div className="steps-content">
                              {isLimit ? (
                                  <Form
                                      className="cart"
                                      action={
                                          window.adt_ticket_data.add_to_cart_url
                                      }
                                      method="post"
                                      encType="multipart/form-data"
                                  >
                                      <>
                                          <FirstStep
                                              show={current === 0}
                                              onSelected={setDate}
                                              onNext={next}
                                              locale={locale}
                                              date={date}
                                              format={DATE_FORMAT}
                                              list={eventList || []}
                                          />
                                          <Input
                                              type="hidden"
                                              name="date"
                                              value={date?.format(DATE_FORMAT)}
                                          />
                                      </>
                                      {isTicketoneProvider && (
                                          <SecondStep
                                              show={current === 1}
                                              onSelected={onSelectedSeatsGroup}
                                              onPrev={prev}
                                              onNext={next}
                                              event={event}
                                              time={seatGroup?.blockText}
                                          />
                                      )}
                                      <Input
                                          type="hidden"
                                          name="time"
                                          value={seatGroup?.blockText}
                                      />
                                      <Input
                                          type="hidden"
                                          name="time_id"
                                          value={seatGroup?.blockId}
                                      />
                                      <ThirdStep
                                          show={
                                              isTicketoneProvider
                                                  ? current === 2
                                                  : current === 1
                                          }
                                          onPrev={prev}
                                          onNext={next}
                                          onSelected={setQuantities}
                                          onSelectedTotalQty={setTotalQty}
                                          event={event}
                                          seatGroup={seatGroup}
                                          limit={available}
                                      />
                                      <FourthStep
                                          show={
                                              isTicketoneProvider
                                                  ? current === 3
                                                  : current === 2
                                          }
                                          onPrev={prev}
                                          onAddToCart={onAddToCart}
                                          quantities={quantities}
                                          limit={available}
                                      />
                                      <Input
                                          type="hidden"
                                          name="event"
                                          value={event?.internalKey}
                                      />
                                      <FinalStep
                                          show={
                                              isTicketoneProvider
                                                  ? current === 4
                                                  : current === 3
                                          }
                                          date={date?.format(DATE_FORMAT)}
                                          time={seatGroup?.blockText}
                                          totalQty={totalQty}
                                          onClickNew={onClickNew}
                                      />
                                  </Form>
                              ) : (
                                  <div className="w-100 h-100 border border-danger">
                                      <LimitReached
                                          phrase={translations["steps.limit"]}
                                      />
                                  </div>
                              )}
                          </div>
                      </div>
                  </Spin>
              </IntlProvider>
          ) : (
              <Spin spinning={true} />
          )}
      </>
  );
};

export default App;
