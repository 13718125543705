import { Input, DatePicker } from "antd";
import React, { useState, useCallback } from "react";
import { Moment } from "moment";

interface SingleNameProps {
  salesType: string;
  index: number;
  onValidation: (salesType: string, index: number, validate: boolean) => void;
}

const SingleName: React.FC<SingleNameProps> = ({
  salesType,
  index,
  onValidation,
}) => {
  const [firstname, setFirstname] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [birthday, setBirthday] = useState<Moment>();

  const checkValid = useCallback(
    (values: string[]) => {
      const notValid = values.findIndex((val) => !val || val === "") >= 0;
      onValidation(salesType, index, !notValid);
    },
    [onValidation, salesType, index]
  );

  const onChangeFirstname = useCallback(
    (e) => {
      setFirstname(e.target.value);
      checkValid([
        e.target.value,
        surname,
        window.adt_ticket_data.provider === "TICKETONE"
          ? birthday?.toISOString()
          : "a",
      ]);
    },
    [setFirstname, checkValid, surname, birthday]
  );
  const onChangeSurname = useCallback(
    (e) => {
      setSurname(e.target.value);
      checkValid([
        e.target.value,
        firstname,
        window.adt_ticket_data.provider === "TICKETONE"
          ? birthday?.toISOString()
          : "a",
      ]);
    },
    [setSurname, checkValid, firstname, birthday]
  );
  const onChangeBirthday = useCallback(
    (val: Moment | null, dateString: string) => {
      setBirthday(val ? val : undefined);
      checkValid([dateString, surname, firstname]);
    },
    [setBirthday, checkValid, surname, firstname]
  );

  const disabledDate = useCallback((currentDate: Moment): boolean => {
    return currentDate && currentDate.valueOf() >= Date.now();
  }, []);

  const isTickaProvider = window.adt_ticket_data.provider === "TICKA";
  const isTicketoneProvider = window.adt_ticket_data.provider === "TICKETONE";

  const classname = ["name-panel"];
  if (isTicketoneProvider) classname.push("name-panel-ticketone");

  return (
    <div className={classname.join(" ")}>
      <Input
        value={firstname}
        placeholder="Nome"
        onChange={onChangeFirstname}
      />
      <Input value={surname} placeholder="Cognome" onChange={onChangeSurname} />
      {isTicketoneProvider && (
        <>
          <DatePicker
            value={birthday}
            placeholder="Data di nascita"
            disabledDate={disabledDate}
            onChange={onChangeBirthday}
          />
          <Input
            type="hidden"
            name="names[]"
            value={`${salesType}_${firstname}_${surname}_${birthday?.format(
              "DD.MM.yyyy"
            )}`}
          />
        </>
      )}
      {isTickaProvider && (
        <>
          <Input
            type="hidden"
            name="names[]"
            value={`${salesType}_${firstname}_${surname}`}
          />
        </>
      )}
    </div>
  );
};
export default SingleName;
