// questa funzione funziona
import sendClientCall from "../client/send-client-call";
export type IUseTicketOneData =  number | null;

const getLimit = async (): Promise<IUseTicketOneData> => {
  const url = window.adt_ticket_data?.ajax_url;
  const productId = window.adt_ticket_data?.product_id;
  
  const result = (await sendClientCall('GET', url, {
      action: "ticket_residual_purchase_limit",
      productId
  }));
  const res: IUseTicketOneData = result >= 0 ? result : null;
  return res;
};

export default getLimit;
