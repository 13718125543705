import React, {useCallback, useEffect } from 'react';
import { Radio, Spin } from 'antd';
import StepContent from './step-content';
import { ITOEvent, ITOSeatGroupByBlocks } from '../../interfaces/ticketone-interfaces';
import { FormattedMessage } from 'react-intl';
import useTicketOneSeatGroupsByBlocks from '../../hooks/use-ticketone-seatgroups-byblocks';
interface SecondStepProps {
    show: boolean;
    onSelected: (seatBlock: ITOSeatGroupByBlocks | undefined) => void;
    onPrev: () => void;
    onNext: () => void;
    event: ITOEvent | undefined;
    time: string | undefined;
}

const SecondStep: React.FC<SecondStepProps> = ({
    show,
    onSelected,
    onPrev,
    onNext,
    event,
    time
}) => {
    const [loadingSeats, seats] = useTicketOneSeatGroupsByBlocks(event?.internalKey);

    useEffect(() => {
        (async () => {
            if (time) {
                const selectSeat = seats?.find(s => s.blockText === time)
                if (!selectSeat?.availableSeats) {
                    onSelected(undefined);
                }
            }
        })();
    }, [seats, onSelected, time]);

    const onTimeSelect = useCallback((e: any) => {
        const blockTime = e.target.value;
        const seatBlock = seats?.find(seat => seat.blockText === blockTime);
        if (seatBlock) {
            onSelected(seatBlock);
        }
    }, [seats, onSelected]);

    return (
        <StepContent 
            show={show} 
            showPrev={true} 
            showNext={true} 
            onPrev={onPrev} 
            onNext={onNext} 
            disableNext={!time}
        >  
            <Spin spinning={loadingSeats} delay={500}>
                <>
                    <p><FormattedMessage id="second.times" /></p>
                    <Radio.Group
                        className='time-radio-group'
                        buttonStyle="solid"
                        onChange={onTimeSelect}
                        value={time}
                    >
                    {
                        seats?.map((value) => (
                            <Radio.Button value={value.blockText} disabled={!value.availableSeats}>
                                {value.blockText}
                                <span className="tooltiptext">
                                    <FormattedMessage id="second.available" />
                                    <strong>{value.availableSeats}</strong>
                                </span>
                            </Radio.Button>
                        ))
                    }
                    </Radio.Group>
                </>
            </Spin>
        </StepContent>
    )
}
export default SecondStep;