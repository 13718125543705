import React, { useState, useCallback } from "react";
import StepContent from "./step-content";
import QuantityPanel from "../quantities/quantity-panel";
import {
  ITOEvent,
  ITOSeatGroupByBlocks,
} from "../../interfaces/ticketone-interfaces";
import { IQuantity } from "../../interfaces/local-interfaces";

interface ThirdStepProps {
    show: boolean;
    onPrev: () => void;
    onNext: () => void;
    onSelected: (quantities: IQuantity[]) => void;
    onSelectedTotalQty: (total: number) => void;
    event: ITOEvent | undefined;
    seatGroup: ITOSeatGroupByBlocks | undefined;
    limit: number | null;
}

const ThirdStep: React.FC<ThirdStepProps> = ({
  show,
  onPrev,
  onNext,
  onSelected,
  onSelectedTotalQty,
  event,
  seatGroup,
  limit,
}) => {
  const [total, setTotal] = useState<number>(0);

  const seatsAvaiable = seatGroup?.availableSeats ?? event?.freeQuantity ?? 0;
  const clientAvailable =
      limit != null && limit <= seatsAvaiable ? limit : seatsAvaiable;

  const onChange = useCallback(
    (quantities: IQuantity[]) => {
      onSelected(quantities);
      const t = quantities.reduce(
        (acc: number, val: IQuantity) => acc + val.value,
        0
      );
      
      setTotal(t);
      onSelectedTotalQty(t);
    },
    [onSelected, onSelectedTotalQty]
  );

  return (
      <StepContent
          show={show}
          showPrev={true}
          showNext={true}
          onPrev={onPrev}
          onNext={onNext}
          disableNext={total === 0}
      >
          {event && (
              <QuantityPanel
                  onChange={onChange}
                  totalQty={seatsAvaiable}
                  maxQuantity={clientAvailable}
                  eventNo={event.internalKey}
                  total={total}
                  seatGroup={seatGroup?.seatGroup ?? undefined}
              />
          )}
      </StepContent>
  );
};
export default ThirdStep;
