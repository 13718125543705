import axios, { AxiosRequestConfig, Method } from "axios";

/**
 * Convert obj to urlencoded
 * @param obj
 */
const objToUrlEncoded = (obj: any): string => {
  const str = [];
  for (const p of Object.keys(obj)) {
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  }
  return str.join("&");
};

/**
 * Send client call
 * @param authToken
 * @param method
 * @param endpoint
 * @param data
 */
const sendClientCall = async <T = any>(
  method: Method,
  endpoint: string,
  data: any = {},
  authToken?: string
): Promise<T> => {
  const headers: any = {};
  if (authToken) {
    headers["Authorization"] = "Bearer " + authToken;
  }
  const config: AxiosRequestConfig = {
    method,
    data,
    headers,
  };
  console.log("LOG METHOD:" + method.toLowerCase());
  const qs =
    method.toLowerCase() === "get" && data ? objToUrlEncoded(data) : "";
  const res = await axios(endpoint + (qs ? "?" + qs : ""), config);

  return res.data;
};

export default sendClientCall;
