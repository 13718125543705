import React, { useState, useEffect, useCallback } from 'react';
import { IQuantity } from '../../interfaces/local-interfaces';
import SingleName from '../quantities/single-name';
import StepContent from './step-content';

interface FourthStepProps {
    show: boolean;
    onPrev: () => void;
    onAddToCart: () => void;
    quantities: IQuantity[] | undefined;
    limit: number | null;
}

const FourthStep: React.FC<FourthStepProps> = ({
    show,
    onPrev,
    onAddToCart,
    quantities,
    limit
}) => {
    const [loadingAddToCart, setLoadingAddToCart] = useState<boolean>(false);
    const [validate, setValidate] = useState<{[key: string]: boolean}>({});
    const [disableAddToCart, setDisableAddToCart] = useState<boolean>(false);

    const updateDisableAddToCart = useCallback((data) => {
        const dis = Object.values(data).findIndex(val => !val) >= 0 || false;
        setDisableAddToCart(dis);
    }, [setDisableAddToCart])

    useEffect(() => {
        const defaultValidate: {[key: string]: boolean} = {};
        quantities?.forEach((val) => {
            Array.from(Array(val.value), (e, i) => {
                defaultValidate[val.salesType + '-' + i] = !!validate[val.salesType + '-' + i];
            })
        })
        setValidate(defaultValidate);
        updateDisableAddToCart(defaultValidate);
    }, [quantities])

    useEffect(() => {
        window.jQuery( document.body ).on('added_to_cart', () => {
            onAddToCart();
            setLoadingAddToCart(false);
        });
    }, [])

    const onClickAdd = useCallback(() => {
        setLoadingAddToCart(true);
    }, []);

    const onValidation = useCallback((salesType: string, index: number, value: boolean) => {
        setValidate(prevData => {
            prevData[salesType + '-' + index] = value;
            updateDisableAddToCart(prevData);
            return prevData;
        });
    }, [setValidate, updateDisableAddToCart]);


    return (
        <StepContent 
            show={show} 
            showPrev={true} 
            onPrev={onPrev} 
            onNext={onClickAdd} 
            showAddToCart={true}
            loadingAddToCart={loadingAddToCart}
            disableNext={validate && disableAddToCart}
            limit={limit}
        >
            <div className="names-block">
            { 
                quantities?.map(quantity => {
                    return (
                        <>
                            <label className="title">{quantity.text}</label>
                            {
                                Array.from(Array(quantity.value), (e, i) => {
                                    return (
                                        <SingleName index={i} salesType={quantity.salesType} onValidation={onValidation}/>
                                    )
                                })
                            }
                        </>
                    )
                })      
            }
            </div>
            
        </StepContent>
    )
}
export default FourthStep;