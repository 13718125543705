import React, { useState, useCallback } from "react";
import { Spin } from "antd";
import SingleQuantity from "../quantities/single-quantity";
import { FormattedMessage } from "react-intl";
import useTicketOnePrices from "../../hooks/use-ticketone-prices";
import useTicketOneSalesTypes from "../../hooks/use-ticketone-salestypes";
import { IQuantity } from "../../interfaces/local-interfaces";
import useLocale from "../../hooks/use-locale";

interface QuantityPanelProps {
  onChange: (quantities: IQuantity[]) => void;
  totalQty?: number;
  maxQuantity: number;
  total: number;
  eventNo: string;
  seatGroup: string | undefined;
}

const QuantityPanel: React.FC<QuantityPanelProps> = ({
  onChange,
  totalQty,
  maxQuantity,
  total,
  eventNo,
  seatGroup,
}) => {
  const [loadingPrices, prices] = useTicketOnePrices(eventNo);
  const [loadingSalesTypes, salesTypes] = useTicketOneSalesTypes(eventNo);
  const [quantities, setQuantities] = useState<IQuantity[]>([]);
  const [, translations] = useLocale();


  const onChangeQuantity = useCallback(
    (value: number, salesType: string, text: string) => {
      setQuantities((prevData) => {
        const qties: IQuantity[] = prevData.filter(
          (q) => q.salesType !== salesType
        );
        if (value > 0) {
          qties.push({
            salesType: salesType,
            value: value,
            text: text,
          });
        }
        onChange(qties);
        return qties;
      });
    },
    [setQuantities, onChange]
  );

  return (
      <Spin spinning={loadingSalesTypes || loadingPrices} delay={500}>
          <span>
              {!!totalQty && totalQty > 0 ? (
                  <span
                      dangerouslySetInnerHTML={{
                          __html: `${translations["third.tickets"]} <strong>${totalQty}</strong> 
                          (${translations["third.tickets_purchase"]} <strong>${maxQuantity}</strong>)`,
                      }}
                  />
              ) : (
                  <span
                      dangerouslySetInnerHTML={{
                          __html: `${translations["third.tickets_purchase"]} <strong>${maxQuantity}</strong>`,
                      }}
                  />
              )}
          </span>
          {false && (
              <p>
                  <FormattedMessage id="third.qty" />
              </p>
          )}
          <div className="adt_quantities">
              {salesTypes
                  ?.filter((salesType) => salesType.status !== "P")
                  .map((salesType) => {
                      let priceElement;
                      if (!!seatGroup) {
                          priceElement = prices?.find(
                              (price) =>
                                  price.salesType === salesType.internalKey &&
                                  price.seatGroup === seatGroup
                          );
                      } else {
                          priceElement = prices?.find(
                              (price) =>
                                  price.salesType === salesType.internalKey
                          );
                      }
                      if (priceElement) {
                          return (
                              <SingleQuantity
                                  salesType={salesType.internalKey}
                                  price={priceElement.price}
                                  text={salesType.text}
                                  seatGroup={seatGroup}
                                  freeQuantity={maxQuantity - total}
                                  onChangeQuantity={onChangeQuantity}
                              />
                          );
                      } else {
                          return <></>;
                      }
                  })}
          </div>
      </Spin>
  );
};
export default QuantityPanel;
