import { ITOPrice } from "../interfaces/ticketone-interfaces";
import useClientCall from "./use-client-call";
export type IUseTicketOnePrices = [loading?: boolean, result?: ITOPrice[]];

const useTicketOnePrices = (eventNo: string): IUseTicketOnePrices => {
  const url = window.adt_ticket_data?.ajax_url;
  const productId = window.adt_ticket_data?.product_id;
  const productionId = window.adt_ticket_data?.to_production_id;

  const response = useClientCall<ITOPrice[]>(
    "GET",
    url,
    {
      action: "ticket_service",
      method: "getPrices",
      eventNo,
      productId,
      productionId,
    },
    []
  );
  return [response[0], response[1]];
};

export default useTicketOnePrices;
