import { ITOSalesType } from "../interfaces/ticketone-interfaces";
import useClientCall from "./use-client-call";
export type IUseTicketOneSalesTypes = [loading?: boolean, result?: ITOSalesType[]];

const useTicketOneSalesTypes = (eventNo: string): IUseTicketOneSalesTypes => {
    const url = window.adt_ticket_data?.ajax_url;
    const productId = window.adt_ticket_data?.product_id;
    const productionId = window.adt_ticket_data?.to_production_id;

    const response = useClientCall<ITOSalesType[]>(
        "GET",
        url,
        {
            action: 'ticket_service',
            method: 'getSalesTypes',
            eventNo,
            productId,
            productionId
        },
        []
    );
    return [response[0], response[1]];
};

export default useTicketOneSalesTypes;
