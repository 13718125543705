import React from 'react';
import { Steps } from 'antd';
const { Step } = Steps;

export interface SingleStep {
    title: string;
    description: string;
}

interface StepsProps {
    current: number;
    steps: SingleStep[];
}

const NavigationSteps: React.FC<StepsProps> = ({current, steps}) => {
    return (
        <Steps 
            current={current}
            progressDot
            size='small'
        >
            {steps.map((item) => (
                <Step 
                    key={item.title} 
                    title={item.title} 
                    description={item.description}
                />
            ))}
        </Steps>
    )
}
export default NavigationSteps;