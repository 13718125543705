import React, { useCallback } from "react";
import { Space, Button, Row } from "antd";
import { ShoppingCartOutlined, RedoOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";

interface FinalStepProps {
  show: boolean;
  date: string | undefined;
  time: string | undefined;
  totalQty: number;
  onClickNew: () => void;
}

const FinalStep: React.FC<FinalStepProps> = ({
  show,
  date,
  time,
  totalQty,
  onClickNew,
}) => {
  const onClick = useCallback((e: any) => {
    window.location.href = window.adt_ticket_data.cart_url;
  }, []);

  return (
    <>
      {show && (
        <Space align="center" direction="vertical">
          <h5>
            <FormattedMessage id="final.tickets" />
            <strong>{totalQty}</strong>
          </h5>
          <h5>
            <FormattedMessage id={!!time ? "final.datetime" : "final.date"} />
            <strong>
              {date}
              {!!time ? " " + time : ""}
            </strong>
          </h5>
          <Row>
            <Button onClick={onClickNew} icon={<RedoOutlined />}>
              <FormattedMessage id="final.new" />
            </Button>
            <Button
              type="primary"
              onClick={onClick}
              icon={<ShoppingCartOutlined />}
            >
              <FormattedMessage id="final.gotocart" />
            </Button>
          </Row>
        </Space>
      )}
      {show && (
        <>
          <div
            className="related-items"
            dangerouslySetInnerHTML={{
              __html: window.adt_ticket_data.relatedItemsHtml,
            }}
          />
        </>
      )}
    </>
  );
};
export default FinalStep;
