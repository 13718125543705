import React from 'react';
import { Button } from 'antd';
import {LoadingOutlined} from '@ant-design/icons'
import { FormattedMessage } from 'react-intl';
import getLimit from '../../hooks/use-ticketone-purchase-limit';

interface StepContentProps {
    show: boolean;
    showPrev?: boolean;
    showNext?: boolean;
    showAddToCart?: boolean;
    loadingAddToCart?: boolean;
    disableNext?: boolean;
    onPrev?: () => void;
    onNext?: () => void;
    limit?:number | null;
}

const StepContent: React.FC<StepContentProps> = ({
    show, 
    children, 
    showPrev, 
    showNext,
    showAddToCart,
    loadingAddToCart,
    disableNext, 
    onPrev, 
    onNext,
    limit
}) => {
    return (
        <div style={{display: show ? 'block' : 'none'}}>
            {children}
            <div className="steps-action">
                {showPrev && (
                <Button 
                    style={{ marginRight: 'auto' }} 
                    onClick={() => onPrev && onPrev()}
                >
                    <FormattedMessage id="buttons.prev"/>
                </Button>
                )}
                {showNext && (
                <Button  
                    type="primary"
                    disabled={disableNext}
                    style={{ marginLeft: 'auto' }} 
                    onClick={() => onNext && onNext()}
                >
                    <FormattedMessage id="buttons.next"/>
                </Button>
                )}
                {showAddToCart && (
                    <Button 
                        style={{ marginLeft: 'auto' }} 
                        htmlType="submit" 
                        type="primary"
                        disabled={disableNext} 
                        onClick={async () => {
                            const newLimit = await getLimit();
                            if(limit && newLimit?.valueOf() !== limit) 
                                window.location.reload();
                            else
                                onNext && onNext();
                        }}
                        value={window.adt_ticket_data.product_id} 
                        className="single_add_to_cart_button"
                    >
                        {loadingAddToCart && <LoadingOutlined />}
                        <FormattedMessage id="buttons.add_to_cart"/>
                    </Button>    
                )}
            </div>
        </div>
    )
}
export default StepContent;