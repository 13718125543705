import { InputNumber, Input } from "antd";
import React, { useState, useCallback } from "react";
import { formatPrice } from "../../utils/formatter";

interface SingleQuantityProps {
  salesType: string;
  text: string;
  price: number;
  seatGroup: string | undefined;
  freeQuantity: number;
  onChangeQuantity: (value: number, salesType: string, text: string) => void;
}

const SingleQuantity: React.FC<SingleQuantityProps> = ({
  salesType,
  text,
  price,
  seatGroup,
  freeQuantity,
  onChangeQuantity,
}) => {
  const [value, setValue] = useState<number>(0);

  const onChange = useCallback((val) => {
    setValue(val);
    onChangeQuantity(val, salesType, text);
  }, []);

  const quantityType = !!seatGroup
    ? `${value}_${salesType}_${seatGroup}`
    : `${value}_${salesType}`;
  return (
    <div className="adt_quantity">
      <label className="title">{text}</label>
      <label className="price">{formatPrice(price)}</label>
      <InputNumber
        size="large"
        min={0}
        max={freeQuantity + value}
        defaultValue={0}
        onChange={onChange}
      />
      <Input type="hidden" name="quantities[]" value={quantityType} />
    </div>
  );
};
export default SingleQuantity;
