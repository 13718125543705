import { useEffect, useState } from "react";
import { ITOEvent } from "../interfaces/ticketone-interfaces";

/**
 * Find event by date
 */
const useFindEvent = (
    eventList: ITOEvent[] | undefined,
    date: string | undefined
): ITOEvent | undefined => {
    const [event, setEvent] = useState<ITOEvent>();

    useEffect(() => {  
        const e = eventList?.find( event => (
            event.date === date
        ));
        setEvent(e);
    }, [eventList?.length, date]);

    return event;
};

export default useFindEvent;