import React, { useState, useCallback } from 'react';
import { Calendar } from 'antd';
import { StopOutlined } from "@ant-design/icons";
import StepContent from './step-content';
import { Moment } from 'moment';
import moment from 'moment';
import 'moment/locale/it';
import itIT from 'antd/lib/date-picker/locale/it_IT';
import enUS from 'antd/lib/date-picker/locale/en_US';
import { ITOEvent } from '../../interfaces/ticketone-interfaces';
import { FormattedMessage } from 'react-intl';

interface FirstStepProps {
    show: boolean;
    date: Moment | undefined;
    onSelected: (date: Moment) => void;
    onNext: () => void;
    locale: string;
    list: ITOEvent[];
    format: string;
}

const FirstStep: React.FC<FirstStepProps> = ({
    show,
    onSelected,
    onNext,
    locale,
    list,
    format,
    date,
}) => {
    const [dateValue, setDateValue] = useState<Moment>();

    const isSameMonth = (date1: Moment| undefined, date2: Moment | undefined): boolean => {
        return (!!date1 && !!date2 && date1.month() === date2.month() && date1.year() === date2.year());
    }

    const dateFullCellRender = useCallback((value: Moment) => {
        const events = list?.filter((ev) => ev.date === value.format(format));
        const freeQuantity = events.reduce((acc, ev) => ev.freeQuantity + acc, 0);
        const classname = ['cell'];
        if (value.isSame(date)) {
            classname.push('selected');
        }
        if (!events.length) {
            classname.push('noevent');
        }
        return (
            <div className={classname.join(' ')}>
                <div className="day">
                    {value.date()}
                </div>
                <div className="content">
                    {events.length > 0 && !freeQuantity && <StopOutlined />}
                    {false && <span className="tooltiptext">
                        <FormattedMessage id="second.available" />
                        <strong>{freeQuantity}</strong>
                    </span>}
                </div>
            </div>
        )
    }, [date, format, list]); 

    const disabledDate = useCallback((value: Moment) => {
        const events = list?.filter((ev) => ev.date === value.format(format));
        const freeQuantity = events.reduce((acc, ev) => ev.freeQuantity + acc, 0);
        return !events.length || !freeQuantity
    }, [list]);

    const onDateSelect = useCallback((value: Moment) => {
        if ((!dateValue && isSameMonth(value, moment())) || isSameMonth(value, dateValue)) {
            onSelected(value);
        }
        setDateValue(value);
    }, [dateValue]);

    const currentLocale = 'it' ? itIT : enUS
    const currentLocaleData = { 
        ...currentLocale,
        lang: {
            ...currentLocale.lang,
            shortMonths: moment().locale(locale).localeData().months()
        }
    }

    return (
        <StepContent 
            show={show}
            showNext={true}
            onNext={onNext}
            disableNext={!date} 
            >
            {false && <p>
                <FormattedMessage id="first.date" />
            </p>}
            <Calendar
                validRange={[moment().add(-1, 'd'), moment().add(1, 'y')]}
                value={dateValue}
                disabledDate={disabledDate}
                dateFullCellRender={dateFullCellRender}
                mode="month"
                onSelect={onDateSelect}
                locale={currentLocaleData}
            />
        </StepContent>
    )
}
export default FirstStep;