import { Method } from "axios";
import { useEffect, useState } from "react";
import { notification } from "antd";
import sendClientCall from "../client/send-client-call";

/**
 * Send client side API call
 * @param method
 * @param endpoint
 * @param data
 * @param initValue
 * @param adminMode
 * @param ignoreErrors
 */
 const useClientCall = <T = any>(
    method: Method,
    endpoint: string | undefined,
    data: any = {},
    initValue: T | undefined = undefined,
): [boolean, T | undefined] => {
    const [result, setResult] = useState<T | undefined>(initValue);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        (async () => {
            if (!endpoint) {
                return;
            }

            setLoading(true);

            try {
                setResult(await sendClientCall(method, endpoint, data));
            } catch (e) {
                notification.warn({ message: 'Errore di comunicazione con il server', duration: 5 });
            } finally {
                setLoading(false);
            }
        })();
    }, [method, endpoint, JSON.stringify(data), setLoading, setResult]);

    return [loading, result];
};

export default useClientCall;
