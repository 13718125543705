import { useEffect, useState } from "react";
import { messages } from '../utils/locale-messages'

const useLocale = (): [string, any] => {
    const [locale, setLocale] = useState<string>('it');

    useEffect(() => {    
        const html: any = document.documentElement;
        const l = html.attributes.lang.value.split('-')[0];
        setLocale(l);
    }, []);

    return [locale, messages[locale]];
};

export default useLocale;
