import { ShoppingCartOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";

interface LimitReachedProps {
    phrase: string;
}

const LimitReached: React.FC<LimitReachedProps> = ({
    phrase
}) => {

    const onClick = useCallback((e: any) => {
        window.location.href = window.adt_ticket_data.cart_url;
    }, []);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifySelf: "center",
            }}
        >
            <span style={{marginBottom:'20px'}} dangerouslySetInnerHTML={{ __html: phrase }} />
            <Button
                type="primary"
                onClick={onClick}
                icon={<ShoppingCartOutlined />}
            >
                <FormattedMessage id="final.gotocart" />
            </Button>
        </div>
    );
};
export default LimitReached;
