import React from "react";
import ReactDOM from "react-dom";
import { ConfigProvider } from "antd";
import "./index.css";
import Ticket from "./components/Ticket";
import reportWebVitals from "./reportWebVitals";

type provider = "TICKA" | "TICKETONE";

declare global {
  interface Window {
      jQuery: any;
      adt_ticket_data: {
          ajax_url: string;
          add_to_cart_url: string;
          cart_url: string;
          product_id: string;
          to_production_id: string;
          to_purchase_limit: number;
          provider: provider;
          theme_color: string;
          relatedItemsHtml: string;
      };
  }
}
if (!window.adt_ticket_data) {
  window.adt_ticket_data = {
    ajax_url: "", //url per chiamare le api di Ticket One. Esempio: 'http://firenzemuseistore.local/wp-admin/admin-ajax.php',
    add_to_cart_url: "",
    cart_url: "",
    product_id: "",
    to_production_id: "2",
    to_purchase_limit: 0,
    provider: "TICKETONE",
    theme_color: "#000",
    relatedItemsHtml: "",
  };
}
ConfigProvider.config({
  theme: {
    primaryColor: window.adt_ticket_data.theme_color,
  },
});
if (document.getElementById("adt_ticket_datepicker")) {
  ReactDOM.render(
    <ConfigProvider>
      <React.StrictMode>
        <Ticket />
      </React.StrictMode>
    </ConfigProvider>,
    document.getElementById("adt_ticket_datepicker")
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
