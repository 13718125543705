import { ITOSeatGroupByBlocks } from "../interfaces/ticketone-interfaces";
import useClientCall from "./use-client-call";
export type IUseTicketOneSeatGroups = [
  loading?: boolean,
  result?: ITOSeatGroupByBlocks[]
];

const useTicketOneSeatGroupsByBlocks = (
  eventNo: string | undefined
): IUseTicketOneSeatGroups => {
  const url = eventNo ? window.adt_ticket_data?.ajax_url : undefined;
  const productId = window.adt_ticket_data?.product_id;

  const response = useClientCall<ITOSeatGroupByBlocks[]>(
    "GET",
    url,
    {
      action: "ticket_service",
      method: "getSeatGroupsAndOccupancyByBlocks",
      eventNo,
      productId,
    },
    []
  );
  return [response[0], response[1]];
};

export default useTicketOneSeatGroupsByBlocks;
