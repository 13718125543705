export const messages: any = {
  en: {
    'title': 'Follow all steps to add to cart your tickets',
    'steps.day': 'Day',
    'steps.hour': 'Hour',
    'steps.quantity': 'Quantity',
    'steps.names': 'Names',
    'steps.done': 'Done',
    'steps.limit': 'Sorry, limit of tickets reached.\n In order to change booking, go to the cart.',
    'buttons.prev': 'Prev',
    'buttons.next': 'Next',
    'buttons.add_to_cart': 'Add to cart',
    'first.date': 'Select Date',
    'second.available': 'Availables: ',
    'second.seats': 'Select Seats Group',
    'second.times': 'Select Time',
    'third.tickets': 'Number of avaiable tickets: ',
    'third.tickets_purchase': 'max purchasable tickets per order: ',
    'third.qty': 'Select quantity',
    'final.tickets': 'Tickets you added to cart: ',
    'final.date': 'Date: ',
    'final.datetime': 'Date & time: ',
    'final.gotocart': 'Go to cart',
    'final.new': 'Add new tickets',
    'ticket.limit': 'Avaiable tickets: ',
  },
  it: {
    'title': 'Segui tutti i passaggi per aggiungere i tuoi biglietti al carrello',
    'steps.day': 'Giorno',
    'steps.hour': 'Ora',
    'steps.quantity': 'Quantità',
    'steps.names': 'Nominativi',
    'steps.done': 'Fine',
    'steps.limit': 'Spiacenti, limite di ticket superato.\n Per cambiare la prenotazione, eliminare la precedente dal carrello.',
    'buttons.prev': 'Indietro',
    'buttons.next': 'Avanti',
    'buttons.add_to_cart': 'Aggiungi al carrello',
    'first.date': 'Seleziona la data',
    'second.available': 'Disponibili: ',
    'second.seats': 'Seleziona la categoria dei posti',
    'second.times': 'Seleziona l\' orario',
    'third.tickets': 'Numero di biglietti disponibili: ',
    'third.tickets_purchase': 'max acquistabili per ordine: ',
    'third.qty': 'Seleziona la quantità',
    'final.tickets': 'Biglietti aggiunti al carrello: ',
    'final.datetime': 'Data e ora: ',
    'final.date': 'Data: ',
    'final.gotocart': 'Vai al carrello',
    'final.new': 'Aggiungi nuovi biglietti',
    'ticket.limit': 'Biglietti disponibili: ',
  },
}