import { ITOEvent } from "../interfaces/ticketone-interfaces";
import useClientCall from "./use-client-call";
export type IUseTicketOneData = [loading?: boolean, result?: ITOEvent[]];

const useTicketOneEvents = (productionId: string): IUseTicketOneData => {
  const url = window.adt_ticket_data?.ajax_url;
  const productId = window.adt_ticket_data?.product_id;

  const response = useClientCall<ITOEvent[]>(
    "GET",
    url,
    {
      action: "ticket_service",
      method: "getEventList",
      productionId,
      productId,
    },
    []
  );
  return [response[0], response[1]];
};

export default useTicketOneEvents;
